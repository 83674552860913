body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


html {
  background-image: none;
  background-color: #EEF6F8;
}

.button.is-primary {
  background-color: #EC0000;
}
.button.is-primary[disabled] {
  background-color: rgb(219, 112, 112);
}
.button.is-primary:hover {
  background-color: rgb(179, 0, 0);
}
.navbar-menu {
  display: none;
}
.button {
  border-radius: 25px;
}
div.navbar-brand {
  margin-left: 0 !important;
  vertical-align: middle;
}
div.navbar-brand a.navbar-item {
  background-image: url('/santander.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 160px;
}

section.header {
  border-bottom: 1px solid #ccc;
}

section.main {  
  margin-top: 2em;
}

.section {
  padding: 0;
}

div.maincolumn {
  background-color: #fff;
}

div.san-login {  
  padding: 1em 5em 1em 5em;
}

div.san-login a {
  text-decoration: underline;
}

div.san-login .title {
  margin-bottom: 1.5em;
}
div.san-login .columns {
  margin-bottom: 2em;
}
div.san-login .column {  
  text-align: center;
}
div.san-login div.loginType {  
  text-align: center;
}
div.san-login div.loginType.selected {
  border-bottom: #D50B07 solid 5px;
}
div.san-login div.loginType.selected i {
  color: #D50B07;
}
div.san-login div.loginType span.loginTypeLabel {
  display: block;
  margin-top: 1em;  
}

div.san-securitydetails {
  text-align: center;
}
div.san-securitydetails i {
  margin-top: 0.5em;
  text-align: center;
  color: #D50B07;
}

div.san-securitydetails h1 {
  margin-top: 1em;
}

div.san-securitydetails fieldset {
  margin: 2em;
}
div.san-securitydetails legend {
  text-align: left;
  font-weight: bold;
}

div.san-securitydetails a {
  text-decoration: underline;
}

div.san-share {
  text-align: center;
}

div.san-share i {
  margin-top: 0.5em;
  text-align: center;
  color: #D50B07;
}

div.san-share h1 {
  margin-top: 1em;
}
div.san-share h2 {
  margin-top: 2em !important;
}

div.san-share a {
  text-decoration: underline;
}



div.san-authorize {
  text-align: center;
  padding: 1em 5em 1em 5em;
}

div.san-authorize > .field > .label {
  text-align: left;
}

div.san-authorize i {
  margin-top: 0.5em;
  text-align: center;
  color: #D50B07;
}

div.san-authorize h1 {
  margin-top: 1em;
}
div.san-authorize h2 {
  margin-top: 2em !important;
}

div.san-authorize a {
  text-decoration: underline;
}


div.san-authorize fieldset {
  margin-top: 2em;
  margin-bottom: 2em;
}
div.san-authorize legend {
  text-align: left;
  font-weight: bold;
}

div.san-authorize i {
  margin-top: 0.5em;
  text-align: center;
  color: #D50B07;
}

div.san-finish {
  text-align: center;
  padding: 1em 5em 1em 5em;
}

div.san-finish i {
  margin-top: 0.5em;
  text-align: center;
  color: #D50B07;
}

div.san-finish h1 {
  margin-top: 1em;
}
div.san-finish h2 {
  margin-top: 2em !important;
}

